
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { ApiDocuments } from "@/core/api";

interface Filter {
  start_date: string;
  end_date: string;
  created_at_start: string;
  created_at_end: string;
  type: string;
  invoice_number: string;
  entity_id: Array<string>;
  order_number: string;
}

export default defineComponent({
  name: "channel-dropdown",
  props: {
    optionsParentId: { type: Array, required: true },
  },
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const cascaderProps = { multiple: true, emitPath: true };
    const typeOptions = ref([]);
    const entityOptions = ref([]);

    const formData = ref<Filter>({
      start_date: "",
      end_date: "",
      created_at_start: "",
      created_at_end: "",
      type: "",
      invoice_number: "",
      entity_id: [],
      order_number: "",
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    const getDataSourceInvoiceType = () => {
      ApiDocuments.getDataSourceInvoiceType()
        .then(({ data }) => {
          if (data.code == 0) {
            typeOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getDataSourceInvoiceMerchant = () => {
      ApiDocuments.getDataSourceInvoiceMerchant()
        .then(({ data }) => {
          if (data.code == 0) {
            entityOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      getDataSourceInvoiceType();
      getDataSourceInvoiceMerchant();
    });

    return {
      t,
      formData,
      formRef,
      typeOptions,
      entityOptions,
      cascaderProps,
      submit,
      handleReset,
    };
  },
});
